// #region Global Imports
import * as React from 'react';
import Link from 'next/link';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import styles from './Logo.module.sass';
// #endregion Local Imports

export const Logo: React.FunctionComponent = () => (
  <Link href="/">
    <div className={cx(styles.Logo__root, 'Logo__root')} />
  </Link>
);
