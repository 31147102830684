// #region Global Imports
import * as React from 'react';
import cx from 'classnames';
import NextImage from 'next/image';
// #endregion Global Imports
// #region Local Imports
import download from './svg/download.svg';
import down from './svg/down.svg';
import print from './svg/print.svg';
import menu from './svg/menu.svg';
import close from './svg/close.svg';
import up from './svg/up.svg';
import search from './svg/search.svg';
import home from './svg/home.svg';
import headphones from './svg/headphones.svg';
import account from './svg/account.svg';
import twitter from './svg/twitter.svg';
import linkedin from './svg/linkedin.svg';
import langDE from './svg/lang_de.svg';
import langFR from './svg/lang_fr.svg';
import styles from './styles.module.sass';
import type { IProps } from './Icon';
// #endregion Local Imports

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const Icon: React.FC<IProps.Icon> = ({
  icon,
  inline = false,
  flex = false,
  language = '',
}) => (
  <span
    className={cx(styles.Icon__root, {
      [`${styles.Icon__root_inline}`]: Boolean(inline),
      [`${styles.Icon__root_block}`]: !inline,
      [`${styles.Icon__root_flex}`]: Boolean(flex),
    })}
  >
    {icon === 'languageIcon' && (
      <NextImage
        alt="lang-icon"
        className={styles.Icon__img}
        height={36}
        src={language === 'fr' ? langDE : langFR}
        width={36}
      />
    )}
    {icon === 'iconLogin' && (
      <NextImage
        alt="account-icon"
        className={styles.Icon__img}
        height={36}
        src={account}
        width={36}
      />
    )}
    {icon === 'iconTwitter' && (
      <NextImage
        alt="account-icon"
        className={styles.Icon__img}
        height={36}
        src={twitter}
        width={36}
      />
    )}
    {icon === 'iconLinkedIn' && (
      <NextImage
        alt="account-icon"
        className={styles.Icon__img}
        height={36}
        src={linkedin}
        width={36}
      />
    )}
    {icon === 'homeIcon' && (
      <NextImage
        alt="home-icon"
        className={styles.Icon__img}
        height={36}
        src={home}
        width={36}
      />
    )}
    {icon === 'searchIcon' && (
      <NextImage
        alt="search-icon"
        className={styles.Icon__img}
        height={36}
        src={search}
        width={36}
      />
    )}
    {icon === 'close' && (
      <NextImage
        alt="close-icon"
        className={styles.Icon__img}
        height={36}
        src={close}
        width={36}
      />
    )}
    {icon === 'menu' && (
      <NextImage
        alt="menu-icon"
        className={styles.Icon__img}
        height={36}
        src={menu}
        width={36}
      />
    )}
    {icon === 'print' && (
      <NextImage
        alt="print-icon"
        className={styles.Icon__img}
        height={36}
        src={print}
        width={36}
      />
    )}
    {icon === 'download' && (
      <NextImage
        alt="download-icon"
        className={styles.Icon__img}
        height={36}
        src={download}
        width={36}
      />
    )}
    {icon === 'up' && (
      <NextImage
        alt="up-icon"
        className={styles.Icon__img}
        height={36}
        src={up}
        width={36}
      />
    )}
    {icon === 'down' && (
      <NextImage
        alt="down-icon"
        className={styles.Icon__img}
        height={36}
        src={down}
        width={36}
      />
    )}
    {icon === 'headphones' && (
      <NextImage
        alt="headphones-icon"
        className={styles.Icon__img_inline}
        height={16}
        src={headphones}
        width={16}
      />
    )}
  </span>
);
/* eslint-enable @typescript-eslint/no-unsafe-assignment */
