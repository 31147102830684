// #region Global Imports
import * as React from 'react';
// #endregion Global Imports

/// BEGIN: JWT-RELATED ///
/// SEE: https://github.com/auth0/jwt-decode/blob/main/index.d.ts
export type JwtToken = string;

export interface JwtPayload {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
}
/// END: JWT-RELATED ///

export const AuthContext = React.createContext<{
  name?: string;
  token?: string;
}>({});
