// #region Global Imports
import React from 'react';
import Script from 'next/script';
// #endregion Global Imports
// #region Local Imports
import { CookieContext } from '@contexts/CookieContext';
// #endregion Local Imports

const GA_MEASUREMENT_ID = 'G-LCLMLJ5BBQ';

export const GoogleAnalytics: React.FC = () => {
  return (
    <CookieContext.Consumer>
      {(value): React.ReactElement | false =>
        Boolean(value.allowsCookies) && (
          <>
            <Script
              src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
              strategy="afterInteractive"
            />
            <Script id="google-analytics" strategy="afterInteractive">
              {` window.dataLayer = window.dataLayer || []; function gtag(){window.dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GA_MEASUREMENT_ID}'); `}
            </Script>
          </>
        )
      }
    </CookieContext.Consumer>
  );
};
