/**
 * This is a little hacky; for now its a time saver.
 */
export function renderBlockContentTranslation(block, fallback = '') {
  return (
    ((block?.translation?.[0]?.value?.__typename ===
      'BlockContentTranslations' &&
      block?.translation?.[0]?.value?.text?.processed) ||
      (block?.translation?.[0]?.value?.__typename ===
        'BlockContentTranslations' &&
        block?.translation?.[0]?.value?.text)) ??
    fallback
  );
}
