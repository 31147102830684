// #region Global Imports
import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
// #endregion Global Imports
// #region Local Imports
import { Icon } from '@components/Icon';
import { LayoutContext } from '@contexts/LayoutContext';
import { gql } from '@generated/gql';
import styles from './MenuShort.module.sass';
// #endregion Local Imports

export const MenuLinkFragment = gql(/* GraphQL */ `
  fragment MenuLinkFragment on MenuLink {
    attribute(key: "class")
    label
    description
    url {
      path
    }
  }
`);

export const MenuShort: React.FC = () => {
  const { locale: language } = useRouter();

  return (
    <LayoutContext.Consumer>
      {(value): React.ReactNode => (
        <div className={styles.MenuShort__root}>
          <ul className={styles.MenuShort__list}>
            {value.menuShort?.entities?.map((link): React.ReactElement => {
              if (link?.__typename === 'MenuLink') {
                const attribute = link.attribute;
                const label = link.label;
                const url = link.url;
                let _url = ''.concat(url?.path ?? '');
                if (typeof language === 'string') {
                  link.attribute === 'languageIcon' &&
                  value.route?.routed === true
                    ? (_url =
                        value.route.translate?.path || '/'.concat(language))
                    : null;
                }
                return _url.includes('https://') ? (
                  <li className={styles.MenuShort__list_item} key={attribute}>
                    <Link
                      className={styles.MenuShort__list_item_a}
                      href={_url}
                      target="_blank"
                    >
                      <div className={styles.MenuShort__list_item_icon}>
                        <div className={styles.MenuShort__list_item_icon_inner}>
                          <Icon icon={attribute!} language={language} />
                        </div>
                      </div>
                      <div className={styles.MenuShort__list_item_text}>
                        <div className={styles.MenuShort__list_entry}>
                          {label}
                        </div>
                      </div>
                    </Link>
                  </li>
                ) : (
                  <li className={styles.MenuShort__list_item} key={attribute}>
                    <Link
                      className={styles.MenuShort__list_item_a}
                      href={_url}
                      locale={false}
                    >
                      <div className={styles.MenuShort__list_item_icon}>
                        <div className={styles.MenuShort__list_item_icon_inner}>
                          <Icon icon={attribute!} language={language} />
                        </div>
                      </div>
                      <div className={styles.MenuShort__list_item_text}>
                        <div className={styles.MenuShort__list_entry}>
                          {label}
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              }

              return <></>;
            })}
          </ul>
        </div>
      )}
    </LayoutContext.Consumer>
  );
};
