import React from 'react';
import cx from 'classnames';
import styles from './styles.module.sass';

export const WrapperRoot: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => (
  <div className={styles.WrapperRoot}>
    <div className={styles.WrapperRoot__inner}>{children}</div>
  </div>
);

export const WrapperMenu: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => <div className={styles.WrapperMenu}>{children}</div>;

export const WrapperGrid: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => <div className={styles.WrapperGrid}>{children}</div>;

// content width 100%
export const WrapperContentFullWidth: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => (
  <div className={cx(styles.WrapperContentFullWidth, className)}>
    {children}
  </div>
);

// left content size
export const WrapperContent: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => (
  <div className={styles.WrapperContent} id="content">
    {children}
  </div>
);

// margin right
export const WrapperContentMargin: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => (
  <div className={styles.WrapperContentMargin}>{children}</div>
);

export const WrapperMargin: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => <div className={styles.WrapperMargin}>{children}</div>;

// within the margin the left side
export const WrapperMarginLeft: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => (
  <div className={cx(styles.WrapperMarginLeft, className)}>{children}</div>
);

// within the margin the right side
export const WrapperMarginRight: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => (
  <div className={cx(styles.WrapperMarginRight, className)}>{children}</div>
);
