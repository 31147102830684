// #region Global Imports
import * as React from 'react';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import { Icon } from '@components/Icon';
import { Logo } from '@components/Header/Logo';
import { MenuMain } from '@components/Header/MenuMain';
import { MenuShort } from '@components/Header/MenuShort';
import styles from './Header.module.sass';
// #endregion Local Imports
export const Header: React.FunctionComponent = () => {
  const [isOpen, setOpen] = React.useState(false);
  const handleClick = (): void => setOpen(!isOpen);
  return (
    <div className={styles.Header__root}>
      <div
        className={cx(
          styles.Menu__root,
          styles[`Menu__root_${isOpen ? 'open' : 'close'}`],
        )}
      >
        <div
          className={cx(
            styles.Menu__inner,
            styles[`Menu__inner_${isOpen ? 'open' : 'close'}`],
          )}
        >
          <button
            className={cx(
              styles.Menu__toggle,
              styles[`Menu__toggle_${isOpen ? 'open' : 'close'}`],
            )}
            onClick={(): void => handleClick()}
            type="button"
          >
            <span className={styles.Menu__caption}>MENU</span>
            <span className={styles.Menu__icon_bg}>
              <Icon icon={isOpen ? 'close' : 'menu'} />
            </span>
          </button>
          <div
            className={cx(
              styles.Menu__content,
              styles[`Menu__content_${isOpen ? 'open' : 'hidden'}`],
            )}
          >
            <div className={styles.Menu__main}>
              <div className={styles.Menu__main_inner}>
                <div className={styles.Menu__logo}>
                  <Logo />
                </div>
                <div className={styles.Menu__main_content}>
                  <MenuMain />
                </div>
              </div>
            </div>
            <div className={styles.Menu__short}>
              <MenuShort />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
