// #region Global Imports
import * as React from 'react';
import Link from 'next/link';
// #endregion Global Imports
// #region Local Imports
import { LayoutContext } from '@contexts/LayoutContext';
import { gql } from '@generated/gql';
import { getFragmentData } from '@generated/fragment-masking';
import { MenuLinkFragment } from '@components/Header/MenuShort';
import styles from './MenuMain.module.sass';
// #endregion Local Imports

export const MenuMainFragment = gql(/* GraphQL */ `
  fragment MenuMainFragment on Query {
    menuMain: menuByName(name: "main", language: $language) {
      entities: links {
        ...MenuLinkFragment
      }
    }
  }
`);

export const MenuMain: React.FunctionComponent = () => {
  return (
    <LayoutContext.Consumer>
      {(value): React.ReactElement => (
        <div className={styles.MenuMain__root}>
          <ul className={styles.MenuMain__list}>
            {value.menuMain?.entities?.map((_link) => {
              const link = getFragmentData(MenuLinkFragment, _link);
              return (
                <li className={styles.MenuMain__list_item} key={link?.label}>
                  <Link href={link?.url?.path ?? ''}>
                    <span className={styles.MenuMain__list_entry}>
                      {' '}
                      {link?.label}{' '}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </LayoutContext.Consumer>
  );
};
