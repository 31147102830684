import * as React from 'react';
import type {
  EntityCanonicalUrl,
  LayoutFragmentFragment,
  TermCookieDialogFragmentFragment,
} from '@generated/graphql';

export type LayoutContextValue = LayoutFragmentFragment &
  TermCookieDialogFragmentFragment & {
    route?: EntityCanonicalUrl;
  };

// NOTE: I choose a provider so that we don't have to pass the data object over every component.
export const LayoutContext = React.createContext<LayoutContextValue>({});
