import extend from 'lodash/extend';

// Make an object serializable to JSON.
// Useful to convert an object which may contain non-serializeable data such as
// Dates to an object that doesn't
export function makeSerializable<T>(o: T): T {
  return JSON.parse(JSON.stringify(o));
}

export function tryParseJson(string = ''): Record<string, unknown> {
  const ret: Record<string, unknown> = Object.create({}) as Record<
    string,
    unknown
  >;

  try {
    extend(ret, JSON.parse(string));
  } catch (e) {
    // Skipping error …
  }

  return ret;
}

export function setCookie(name: string, value: string, days: number): string {
  let expires = '';
  if (days !== 0) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  return (document.cookie = `${name}=${value}${expires}; path=/`);
}

export function getCookie(name: string): string {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c?.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c?.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return '';
}

export function eraseCookie(name: string): void {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
