'use client';
// #region Global Imports
import * as React from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { Dialog } from '@headlessui/react';
// #endregion Global Imports
// #region Local Imports
import { getCookie, setCookie, tryParseJson } from '@lib/utils';
import type { IState } from '@components/Dialog/Cookies/Cookie';
import { gql } from '@generated/gql';
import { renderBlockContentTranslation } from '@lib/func/renderBlockContentTranslation';
import { LayoutContext } from '@contexts/LayoutContext';
import styles from './Cookies.module.sass';
// #endregion Local Imports

export const TermCookieDialogFragment = gql(/* GraphQL */ `
  fragment TermCookieDialogFragment on Query {
    termCookieInfo: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Cookie Info" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termCookieAccept: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Cookie Accept" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termCookieReject: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Cookie Reject" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termCookieFurther: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Cookie Further" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldText {
              processed
            }
          }
        }
      }
    }
  }
`);

export const Cookies: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter();


  const [state, setState] = React.useState<IState.Cookies>({
    isOpen: false,
    hasPreferences: null,
  });

  const openModal = React.useCallback((): void => {
    setState({
      ...state,
      isOpen: true,
    });
  }, []);

  const closeModal = React.useCallback((): void => {
    setState({
      ...state,
      isOpen: false,
    });
  }, []);

  async function saveCookiePreferences(payload: boolean): Promise<void> {
    if (typeof window !== 'undefined') {
      const v = JSON.stringify({
        allowsCookies: payload,
      });

      closeModal();
      Boolean(setCookie('preferences', v, 356));
      return;
    }

    await router.push('/500');
  }

  React.useEffect(() => {
    // NOTE: JSON.parse throws an error on empty objects… wrapping in try catch
    const value = tryParseJson(getCookie('preferences'));
    if ('allowsCookies' in value) {
      return closeModal();
    }

    return openModal();
  }, [closeModal, openModal]);

  if (
    router.asPath.includes('online-datenschutzerklaerung') ||
    router.asPath.includes('declaration-de-confidentialite-en-ligne')
  ) {
    return (
      <LayoutContext.Consumer>
        {(ctx): React.ReactElement => (
          <>
            <div className={cx(styles.Cookies__inner, styles.Cookies__inner_float)}>
              <div>
                {
                  renderBlockContentTranslation(
                    ctx.termCookieInfo,
                    'trex.ch uses cookies to constantly optimize the website and services. to be able to optimize the website and services.',
                  ) as string
                }
                <input
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async (): Promise<void> => {
                    saveCookiePreferences(false);
                    router.back();
                  }}
                  type="submit"
                  value={
                    renderBlockContentTranslation(
                      ctx.termCookieReject,
                      'Reject',
                    ) as string
                  }
                />
                <input
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async (): Promise<void> => {
                    await saveCookiePreferences(true);
                    router.back();
                  }}
                  type="submit"
                  value={
                    renderBlockContentTranslation(
                      ctx.termCookieAccept,
                      'Accept',
                    ) as string
                  }
                />
              </div>
            </div>
            {children}
          </>
        )}
      </LayoutContext.Consumer>
    );
  }

  return (
    <LayoutContext.Consumer>
      {(ctx): React.ReactElement => (
        <>
          {!state.hasPreferences && (
            <Dialog
              className={styles.Cookies__wrapper}
              onClose={(): null => null}
              open={state.isOpen}
            >
              <div className={styles.Cookies__root}>
                <Dialog.Panel className={styles.Cookies__inner}>
                  <Dialog.Description>
                    <div className={styles.Cookies__body}>
                      {
                        renderBlockContentTranslation(
                          ctx.termCookieInfo,
                          'trex.ch uses cookies to constantly optimize the website and services. to be able to optimize the website and services.',
                        ) as string
                      }
                      <input
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={async (): Promise<void> =>
                          saveCookiePreferences(false)
                        }
                        type="submit"
                        value={
                          renderBlockContentTranslation(
                            ctx.termCookieReject,
                            'Reject',
                          ) as string
                        }
                      />
                      <input
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={async (): Promise<void> => {
                          await saveCookiePreferences(true);
                        }}
                        type="submit"
                        value={
                          renderBlockContentTranslation(
                            ctx.termCookieAccept,
                            'Accept',
                          ) as string
                        }
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: renderBlockContentTranslation(
                            ctx.termCookieFurther,
                            'Further information ',
                          ) as string,
                        }}
                        style={{
                          marginTop: '1em',
                        }}
                      />
                    </div>
                  </Dialog.Description>
                </Dialog.Panel>
              </div>
            </Dialog>
          )}
          {children}
        </>
      )}
    </LayoutContext.Consumer>
  );
};
