// #region Global Imports
import * as React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import dynamic from 'next/dynamic';
// #endregion Global Imports
// #region Local Imports
import type { LayoutContextValue } from '@contexts/LayoutContext';
import { LayoutContext } from '@contexts/LayoutContext';
import * as Wrapper from '@components/Wrapper';
import { Header } from '@components/Header';
import { getFragmentData, gql } from '@generated/index';
import { AuthContext } from '@contexts/AuthContext';
import { Cookies } from '@components/Dialog/Cookies';
import { Spinner } from '@components/Spinner';
import { CookieContext } from '@contexts/CookieContext';
import { GoogleAnalytics } from '@components/GoogleTagManager';
import type { IDefaultSiteTitle, IProps } from './Layout';
// #endregion Local Imports

const Adition = dynamic(
  () => import('@components/Adition').then((mod) => mod.Adition),
  {
    loading: () => <Spinner />,
    ssr: false,
  },
);

const Footer = dynamic(
  () => import('@components/Footer').then((mod) => mod.Footer),
  {
    loading: () => <Spinner />,
  },
);

export const Layout: React.FC<React.PropsWithChildren<IProps.Layout>> = ({
  children,
  data: _data,
  user,
  language,
  title = '',
  header,
}) => {
  const { locale } = language;
  const data = getFragmentData(LayoutFragment, _data);

  // State to track the user value
  const [currentUser, setCurrentUser] = React.useState(user);

  // Update the currentUser state when the user prop changes
  React.useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  const defaultSiteTitle: IDefaultSiteTitle.Layout = {
    de: 'Der Treuhandexperte',
    fr: 'L’expert Fiduciaire',
  };

  return (
    <LayoutContext.Provider value={data as LayoutContextValue}>
      <CookieContext.Provider value={{ ...user?.preferences }}>
        <Head>
          <title>
            {`TREX – ${String(title || (locale === 'de' ? String(defaultSiteTitle.de) : String(defaultSiteTitle.fr)))}`}
          </title>
          <meta charSet="UTF-8" />
          <meta
            content="width=device-width, initial-scale=1.0"
            name="viewport"
          />
          <meta content="ie=edge" httpEquiv="X-UA-Compatible" />
          <link color="#333333" href="/safari-pinned-tab.svg" rel="mask-icon" />
          <link
            href="/favicon-32x32.png"
            rel="icon"
            sizes="32x32"
            type="image/png"
          />
          <link
            href="/favicon-16x16.png"
            rel="icon"
            sizes="16x16"
            type="image/png"
          />
          <link href="/site.webmanifest" rel="manifest" />
          <meta content="#ffffff" name="msapplication-TileColor" />
          <meta content="/mstile-144x144.png" name="msapplication-TileImage" />
          <meta content="#ffffff" name="theme-color" />
          <meta
            content="upgrade-insecure-requests"
            httpEquiv="Content-Security-Policy"
          />
        </Head>
        <AuthContext.Provider value={currentUser ?? {}}>
          <Script
            src="//imagesrv.adition.com/js/srp.js"
            strategy="afterInteractive"
          />
          <Script
            src="//imagesrv.adition.com/js/adition.js"
            strategy="afterInteractive"
          />
          <Cookies>
            <Wrapper.WrapperRoot>
              <Wrapper.WrapperGrid>
                <Adition
                  active
                  adId={[4473031, 4504601]} // [de, fr]
                  carousel
                  slot="BILLBOARD_728x90_994x250_1"
                  style="billboard"
                />
                <Adition
                  adId={[4508861, 4508862]} // [de, fr]
                  carousel
                  slot="BILLBOARD_728x90_994x250_2"
                  style="billboard"
                />
                <Wrapper.WrapperMenu>
                  <Header />
                  {Boolean(header) &&
                    React.createElement(React.Fragment, undefined, header)}
                </Wrapper.WrapperMenu>
                {children}
                {Boolean(data.footer) && <Footer />}
              </Wrapper.WrapperGrid>
            </Wrapper.WrapperRoot>
          </Cookies>
          <GoogleAnalytics />
        </AuthContext.Provider>
      </CookieContext.Provider>
    </LayoutContext.Provider>
  );
};

export const LayoutFragment = gql(/* GraphQL */ `
  fragment LayoutFragment on Query {
    # No need for explicit fragments since they get consumed by the provider.
    footer: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [{ field: "type", value: "footer", language: $language }]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentFooter {
            id
            title: fieldTitle
            text: fieldText {
              processed
            }
          }
        }
      }
    }

    menuMain: menuByName(name: "main", language: $language) {
      entities: links {
        ...MenuLinkFragment
      }
    }

    menuShort: menuByName(name: "secondary", language: $language) {
      entities: links {
        attribute(key: "class")
        label
        description
        url {
          path
        }
      }
    }

    route(path: $path) {
      routed
      translate(language: $translate) {
        path
      }
    }

    ...TermCookieDialogFragment
  }
`);
